import { NavbarElement } from '@/interfaces'
import { AuthenticationInformation } from '@/oidc'

export function getNavElements(user: AuthenticationInformation): NavbarElement[] {
  return [
    {
      id: 'fruitcore-main',
      title: 'fruitcore.com',
      icon: 'bi-fc-logo',
      class: 'uppercase',
      externalLink: process.env.VUE_APP_FRUITCORE_ROBOTICS_HOME,
      visible: () => user.user?.profile.email !== process.env.VUE_APP_MESSE_USER,
    },
    {
      id: 'fleet',
      title: 'MEINE ROBOTER',
      titleLocale: 'messages.navbar.myRobots',
      class: 'uppercase',
      icon: 'bi-fc-horst-900',
      link: '/fleet',
    },
    {
      id: 'licenses',
      title: 'MEINE LIZENZEN',
      titleLocale: 'messages.navbar.myLicenses',
      class: 'uppercase',
      icon: 'bi-fc-license',
      link: '/licenses',
    },
    {
      id: 'issues',
      title: 'MEIN SERVICE',
      titleLocale: 'messages.navbar.myService',
      class: 'uppercase',
      icon: 'bi-wrench-adjustable-circle-fill',
      link: '/service',
    },
    {
      // TODO change icon
      id: 'internal',
      title: 'fruitcore intern',
      icon: 'bi-shield-lock-fill',
      link: '/internal',
      visible: () => user.isInternalUser,
    },
    {
      id: 'documentation',
      title: 'DOKUMENTATION',
      titleLocale: 'messages.navbar.documentation',
      icon: 'bi-fc-doc',
      link: '/documentation',
    },
    {
      id: 'horst-fx',
      title: 'horstFX',
      icon: 'bi-fc-horstFX',
      link: '/horstfx/options',
    },
  ]
}
